<template>
  <b-row>
    <b-col sm="12">
      <b-card header-tag="header" footer-tag="footer">
        <b-row>
          <b-col sm="7">
            <h4 id="traffic" class="card-title mb-0">Stock por Productos</h4>
            <div class="small text-muted">Consulta del movimiento de stock por producto</div>
          </b-col>                             
        </b-row>
      </b-card>
      
      <b-card no-body>
        <b-tabs card>
          <b-tab title="Productos" active>
            <b-row>
              <b-col md="4" class="align-self-center">
                <FindObject render="search"
                            type="products"                            
                            @select-object="loadProducts($event)" 
                            :valueID="filters.products_id"
                            :key="filters.products_compound_id"/>          
              </b-col>
              <b-col md="4" class="align-self-center">
                <b-button variant="outline-dark" size="sm" @click="filterReport()">
                  <b-icon icon="filter" size="sm" class="mr-1" style="font-size:15px;"></b-icon>              
                  Filtrar
                </b-button>
              </b-col>
            </b-row>
          </b-tab>
          <b-tab title="Productos Compuestos" v-if="parameters.haveProductsCompound">
            <b-row>
              <b-col md="4" class="align-self-center">            
                <FindObject render="search"
                            type="productsCompound" 
                            @select-object="loadProductsCompound($event)" 
                            :valueID="filters.products_compound_id"
                            :key="filters.products_id"
                            :where="conditionProductsCompoundActive"/>       
              </b-col>
              <b-col md="4" class="align-self-center">
                <b-button variant="outline-dark" size="sm" @click="filterReport()">
                  <b-icon icon="filter" size="sm" class="mr-1" style="font-size:15px;"></b-icon>              
                  Filtrar
                </b-button>
              </b-col>
            </b-row>                               
          </b-tab>        
        </b-tabs>
      </b-card>

      <b-row>
        <b-col md="4">
          <b-card>
            <div v-if="!treeLoader">
              <vue-tree-list
                @click="selectedItem"              
                :model="depositsTree"
                v-bind:default-expanded="true"              
                v-if="deposits && deposits.length"
                class="deposit-query-tree-custom"
              >                      
                <template v-slot:leafNameDisplay="slotProps">                
                  <span>
                    &nbsp;&nbsp;{{ slotProps.model.name }}
                  </span>
                </template>              

                <span class="icon" slot="treeNodeIcon">🏬&nbsp;</span>              
                <span class="icon" slot="leafNodeIcon">📌&nbsp;</span>
              </vue-tree-list>  
              <b-alert v-else variant="warning" show>No se encontraron registros</b-alert>                               
            </div>
            <div class="tree-deposit-query" v-else>
              <b-spinner label="Spinning"></b-spinner>
            </div>
          </b-card>         
        </b-col>

        <b-col md="8" v-if="deposits && deposits.length">
          <b-card>            
            <b-row>
              <b-col md="8">
                <b-form-radio-group v-model="filters.typeQuery" 
                                    :options="optTypeQuery" 
                                    @input="setTypeQuery(treeSelectItem)">
                </b-form-radio-group>            
              </b-col>
              <b-col md="4">              
                <b-form-checkbox  v-model="filters.showWithoutStock"  
                                  switch 
                                  size="sm" 
                                  class="pull-right"
                                  @input="setTypeQuery(treeSelectItem)"
                                  v-if="filters.typeQuery == 'group'">
                  Mostrar Productos Sin Stock
                </b-form-checkbox>   
              </b-col>
              <b-col md="12" class="mt-1">
                <b-table class="mb-0"
                        responsive="sm"
                        head-variant="dark"
                        :hover="true"
                        :small="true"
                        :fixed="false"
                        :items="table.items"
                        :fields="table.fields"
                        :filter="table.filter"
                        :current-page="table.currentPage"
                        :per-page="table.perPage"
                        :busy="table.isBusy"   
                        v-if="table.items.length || table.isBusy">

                    <template v-slot:table-busy>
                      <div class="text-center text-danger my-2">
                        <b-spinner class="align-middle"></b-spinner>
                        <strong>Cargando...</strong>
                      </div>
                    </template>
                    
                    <template v-slot:cell(date)="data">
                      <div v-if="data.item.operation">
                        {{moment(data.item.operation.date).format('DD/MM/YYYY')}}
                      </div>
                    </template>   

                    <template v-slot:cell(productCode)="data">
                      <div v-html="getDetailCode(data.item)"></div>
                    </template>     

                    <template v-slot:cell(productName)="data">
                      <div v-html="getDetailName(data.item)"></div>
                    </template>   

                    <template v-slot:cell(operation)="data">                                 
                      <div v-if="data.item.operation.type">
                        {{data.item.operation.type.name}} #{{data.item.operation.number}}                        
                      </div>
                    </template>

                    <template v-slot:cell(quantity)="data">     
                      <span v-if="filters.typeQuery == 'mov'">                            
                        {{data.item.type_balance}} {{data.item.quantity}}
                      </span>
                      <span v-else>                            
                        {{data.item.quantity}}
                      </span>                  
                      <span v-if="data.item.unit_measurement">{{data.item.unit_measurement.reference}}</span>
                    </template>

                    <template v-slot:cell(location)="data">                                 
                      <span v-if="data.item.location && data.item.location.zone && data.item.location.zone.deposit">
                        {{data.item.location.zone.deposit.reference}} /
                        {{data.item.location.zone.reference}} /
                        {{data.item.location.reference}}
                      </span>                  
                    </template>                

                </b-table>
                <b-alert v-else variant="warning" show>No se encontraron registros</b-alert>
              </b-col>              
              <b-col md="12" v-if="table.items.length && !table.isBusy">
                <hr>
                <div class="deposit-stock-total">
                  <b-row>
                    <b-col md="10">
                      <div class="deposit-stock-total-title">
                        TOTAL STOCK
                      </div>
                    </b-col>
                    <b-col md="2">
                      <span v-html="getStockTotal()"></span>
                    </b-col>
                  </b-row>
                </div>
              </b-col>              
            </b-row>
          </b-card>
        </b-col>
      </b-row>
      

      <b-card header-tag="header" footer-tag="footer">
        <b-row>
          <b-col>
            <b-button type="button" variant="outline-dark" size="sm" @click="$router.go(-1)">
              <i class="fa fa-angle-double-left"></i>
              Volver
            </b-button>                        
          </b-col>

          <b-col>
            <nav>
              <b-pagination class="pull-right mb-0"
                            size="sm"
                            pills=""
                            :total-rows="getRowCount(table.items)"
                            :per-page="table.perPage"
                            v-model="table.currentPage" />
            </nav>
          </b-col>          
        </b-row>
      </b-card>
  
    </b-col>
  </b-row>
</template>
<script>
  import serviceAPI from './services'
  import Error from '@/handler/error'
  import Session from '@/handler/session' 
  import Funciones from '@/handler/funciones' 
  import Profiles from '@/config/profiles'
  import Modules from '@/config/modules'
  import Helper from '@/handler/helper' 
  import FindObject from '@/components/inc/find/findObject' 
  import Param from '@/config/parameters'

  import Vue from 'vue'
  import { VueTreeList, Tree, TreeNode } from 'vue-tree-list'
  Vue.use(VueTreeList)  

  export default {
    components: {
      FindObject,
      VueTreeList,
    },    
    data: () => {
      return {      
        access: {
          module_id: Modules.DEPOSITOS,
          profile_id: Profiles.PERSONAL,
          view_reference: 'crudDepositsQuery',
          elements: {}
        }, 
        deposits: null,
        depositsTree: new Tree([]),
        table : {
          items: [],
          fields: [],
          isBusy: false,
          filter: null,
          currentPage: 1,
          perPage: 50,
        },     
        filters: {
          products: null,
          products_id: 0,
          products_compound: null,
          products_compound_id: 0, 
          location_id: 0,      
          type: 'products',
          typeQuery: 'group',
          showWithoutStock: false,
        },              
        treeLoader: false,
        treeSelectItem: null,
        keyForceUpdate: 0,            
        optTypeQuery: [
          { text: 'Agrupado por Productos', value: 'group'},
          { text: 'Movimiento de Entrada y Salida', value: 'mov'},
        ],
        parameters: {
          haveProductsCompound: Helper.hasParametersAccess(Param.P30),
        }
      }
    },
    created () {     
      /* Configurar permisos de vistas y elementos */
      Helper.hasAccessView(this.access)
      /* Fin configuracion */
    },
    computed: {
      // CONDITIONS SELECT  
      conditionProductsCompoundActive() {
        return [{field: 'active', condition: true}];
      }, 
    },  
    mounted() {
      this.loadFieldTable()
    },
    methods: {
      loadFieldTable() {
        this.table.fields = []
        
        if(this.filters.typeQuery=='mov') {
          this.table.fields.push({key: 'date', label: 'Fecha', class: 'align-middle'})
          this.table.fields.push({key: 'operation', label: 'Operación', class: 'align-middle'})
        }

        this.table.fields.push({key: 'location', label: 'Ubicación', sortable: true, class: 'align-middle'}) 
        this.table.fields.push({key: 'productCode', label: 'Código', class: 'align-middle'})
        this.table.fields.push({key: 'productName', label: 'Producto', class: 'align-middle'})                           
        this.table.fields.push({key: 'quantity', label: 'Cantidad', class: 'align-middle text-right'})        
      },
      getRowCount (items) {
        return items.length
      },

      forceUpdate() {
        this.keyForceUpdate = this.keyForceUpdate + 1
      },

      loadProducts (object) {        
        if(object){
          this.filters.products = object
          this.filters.products_id = object.id           
          
          this.filters.products_compound = null
          this.filters.products_compound_id = 0

          this.filters.type = 'products'

          this.filterReport()
        } else {
          this.filters.products = null
          this.filters.products_id = 0
        }
      },
      loadProductsCompound (object) {        
        if(object){
          this.filters.products_compound = object
          this.filters.products_compound_id = object.id        

          this.filters.products = null
          this.filters.products_id = 0

          this.filters.type = 'products_compound'

          this.filterReport()
        } else {
          this.filters.products_compound = null
          this.filters.products_compound_id = 0
        }
      },

      loadDeposits() {                
        var result = serviceAPI.filtrarActivityGroupDeposit(this.filters)
        
        result.then((response) => {          
          this.treeLoader = false
          
          this.deposits = [];  
          this.depositsTree = new Tree(this.deposits);  

          var data = response.data
          if(data.length) {
            var arrDeposits = []
            data.forEach(element => {                         
              var deposit_id = 0
              var deposit_name = 'SIN DEPOSITO'
              if(element.location.zone.deposit) {
                deposit_id = element.location.zone.deposit.id
                deposit_name = element.location.zone.deposit.name + ' (' + element.location.zone.deposit.reference + ')'
              }

              arrDeposits.push({
                id: deposit_id,
                name: deposit_name,
                zone_id: element.location.zone_id,
                zone: {
                  id: element.location.id,
                  name: element.location.zone.reference + ' | ' + element.location.reference,
                }
              })
            });
              
            arrDeposits = Funciones.sortArrayInteger(arrDeposits,'zone_id','asc')              
            
            var groupDeposit = []            
            arrDeposits.forEach(element => {
              if(groupDeposit[element.id] == undefined) {
                groupDeposit[element.id] = [] 
                groupDeposit[element.id].id = element.id
                groupDeposit[element.id].name = element.name
                groupDeposit[element.id].children = []
              }
            });            
            groupDeposit = groupDeposit.filter(Boolean)
            
            this.configTree(groupDeposit, arrDeposits)
          }           
        })
        .catch(error => {                  
          this.treeLoader = false
          this.$awn.alert(Error.showError(error))
        });    
      },      

      configTree(group, data) { 
        this.deposits = []             

        group.forEach((element, index) => {                                                                    
          this.deposits.push({            
            id: element.id,                  
            name: element.name,            
            dragDisabled: true,
            addTreeNodeDisabled: true,
            addLeafNodeDisabled: true,
            editNodeDisabled: true,
            delNodeDisabled: true,                     
            children: [],
          })

          this.getChildren(this.deposits[index], data)
        })                  

        this.depositsTree = new Tree(this.deposits)
      },
      getChildren(obj, data) {     
        if(data) {        
          data.forEach(element => {        
            if(obj.id == element.id) {
              var existe = false
              obj.children.forEach(item => {
                if(item.id == element.zone.id) {
                  existe = true
                }
              });

              if(!existe) {
                obj.children.push({
                  id: element.zone.id,      
                  name: element.zone.name,                
                  dragDisabled: true,
                  addTreeNodeDisabled: true,
                  addLeafNodeDisabled: true,
                  editNodeDisabled: true,
                  delNodeDisabled: true,                  
                  isLeaf: true,                            
                })    
              }                                  
            }  
          });     
        }   
      },
      selectedItem(params) {     
        if(params.isLeaf) {          
          this.filterReport(params.id, false)
          this.treeSelectItem = params
        } else {
          this.filterReport(null, false)
          this.treeSelectItem = null          
        }       
      },

      setTypeQuery(params) {
        if(this.filters.typeQuery != 'group') {
          this.filters.showWithoutStock = false
        }

        if(params) {
          this.filterReport(params.id, false)    
        } else {
          this.filterReport(null, false)    
        }
      },

      filterReport(location_id, loadDeposits = true) {                
        this.filters.location_id = location_id

        if(loadDeposits) { 
          this.deposits = null
          this.treeLoader = true
        }

        this.table.isBusy = true        
        var result = serviceAPI.filtrarActivity(this.filters)

        result.then((response) => {          
          var data = response.data.data          
          this.table.items = data          
          this.table.isBusy = false

          this.loadFieldTable()

          if(loadDeposits) {
            this.loadDeposits()
          }          
        })
        .catch(error => {
          this.table.isBusy = false
          this.$awn.alert(Error.showError(error))
        });                
      },
      getStockTotal() {
        var total = 0
        if(this.table.items.length) {
          this.table.items.forEach(element => {
            if(element.type_balance) {
              if(element.type_balance == '+') {
                total = total + Math.abs(parseFloat(element.quantity))
              } else {
                total = total - Math.abs(parseFloat(element.quantity))
              }
            } else {
              total = total + parseFloat(element.quantity)
            }
          });
          if(parseFloat(total)<0) {
            total = '<span style="color:red">' + parseFloat(total).toFixed(2) + " " + this.table.items[0].unit_measurement.reference + "</span>"  
          } else {
            total = '<span style="color:green">' + parseFloat(total).toFixed(2) + " " + this.table.items[0].unit_measurement.reference + "</span>"  
          }                   
        }
        return total
      },

      // PRODUCTOS
      getDetailCode(item) {        
        if(item.products_id || item.products_colors_id || item.products_waist_id || item.products_color_waist_id) {
          return this.getProductCode(item)
        }            
        if(item.products_compound_id) {          
          return item.products_compound.code
        }                
      },
      getDetailName(item) {        
        if(item.products_id || item.products_colors_id || item.products_waist_id || item.products_color_waist_id) {
          return this.getProductName(item)
        }               
        if(item.products_compound_id) {          
          return item.products_compound.name
        }                
      },       
      getProductCode(item) {
        if(item.products_id) {
          return item.product.code
        }

        if(item.products_colors_id) {
          var prod = item.products_color 
          return prod.code
        }

        if(item.products_waist_id) {
          var prod = item.products_waist 
          return prod.code
        }

        if(item.products_color_waist_id) {
          var prod = item.products_color_waist 
          return prod.code
        }
      },      
      getProductName(item) {
        if(item.products_id) {
          return item.product.name
        }

        if(item.products_colors_id) {
          var prod = item.products_color 
          return prod.name + ' (' + prod.color.name + ')'
        }

        if(item.products_waist_id) {
          var prod = item.products_waist 
          return prod.name + ' (' + prod.waist.name + ')'
        }

        if(item.products_color_waist_id) {
          var prod = item.products_color_waist 
          return prod.name + ' (' + prod.color.name + ' - ' + prod.waist.name + ')'
        }
      },        
    }    
  }
</script>

<style>
  .tree-deposit-query {    
    text-align: center;
    margin-top: 20px;    
  }     
  .deposit-query-tree-custom .icon:hover {
    cursor: pointer;
  }
  .deposit-query-tree-custom .vtl-node-content:hover {
    cursor: pointer;
  }   
  .deposit-stock-total {
    text-align: right;
    font-size: 18px;    
  }
  .deposit-stock-total {
    border-style: solid;
    border-width: 1px;
    border-collapse: var(--gray-dark);
  }
  .deposit-stock-total span {
    text-align: right;
    font-size: 18px;
    font-weight: bold;    
    margin-right: 5px;
  }  
  .deposit-stock-total-title {
    background: var(--gray-dark);
    color: #fff;
    font-weight: 600; 
    padding-right: 10px;   
  }
</style>